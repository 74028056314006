@charset "UTF-8";

.preview-area {
  margin: 0;
  line-height: 2;
  color: #333;
  // background: #eee;
  font-size: 10pt;
  font-family: 'Noto Sans JP', sans-serif;
  overflow-x: hidden;

  /*
  html5doctor.com Reset Stylesheet
  v1.6.1
  Last Updated: 2010-09-17
  Author: Richard Clark - http://richclarkdesign.com
  Twitter: @rich_clark
  */
  html,
  body,
  div,
  span,
  object,
  iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  abbr,
  address,
  cite,
  code,
  del,
  dfn,
  em,
  img,
  ins,
  kbd,
  q,
  samp,
  small,
  strong,
  sub,
  sup,
  var,
  b,
  i,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  canvas,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  menu,
  nav,
  section,
  summary,
  time,
  mark,
  audio,
  video {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
    box-sizing: border-box;
  }

  body {
    line-height: 1;
  }

  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  menu,
  nav,
  section {
    display: block;
  }

  nav ul {
    list-style: none;
  }

  blockquote,
  q {
    quotes: none;
  }

  blockquote:before,
  blockquote:after,
  q:before,
  q:after {
    content: '';
    content: none;
  }

  a {
    margin: 0;
    padding: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
  }

  /* change colours to suit your needs */
  ins {
    background-color: #ff9;
    color: #000;
    text-decoration: none;
  }

  /* change colours to suit your needs */
  mark {
    background-color: #ff9;
    color: #000;
    font-style: italic;
    font-weight: bold;
  }

  del {
    text-decoration: line-through;
  }

  abbr[title],
  dfn[title] {
    border-bottom: 1px dotted;
    cursor: help;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  /* change border colour to suit your needs */
  hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #cccccc;
    margin: 1em 0;
    padding: 0;
  }

  input,
  select {
    vertical-align: middle;
  }

  body {
    -webkit-print-color-adjust: exact;
  }

  header {
    background-color: #fff !important;
  }

  /* A4縦 */

  /* ---------------------------------------------------
  Foundation
  --------------------------------------------------- */

  /* Base
  ----------------------------------------------- */

  body {
    margin: 0;
    line-height: 2;
    color: #333;
    background: #eee;
    font-size: 10pt;
    font-family: 'Noto Sans JP', sans-serif;
    overflow-x: hidden;
  }

  img,
  video {
    max-width: 100%;
    height: auto;
  }

  a {
    color: #d4145a;
  }

  strong {
    color: #777;
  }

  /*clearfix*/

  .clearfix:after {
    content: '';
    display: block;
    clear: both;
  }

  .clearfix {
    zoom: 1;
  }

  .row:after {
    content: '';
    clear: both;
    display: block;
  }

  .m0 {
    margin-bottom: 0 !important;
  }

  .m30 {
    margin-bottom: 30px !important;
  }

  .m60 {
    margin-bottom: 60px !important;
  }

  .m120 {
    margin-bottom: 120px !important;
  }

  .mt60 {
    margin-top: 60px !important;
  }

  .c-break {
    display: inline-block;
  }

  .c-break02 {
    display: inline-block;
  }

  .c-page_break {
    page-break-before: always;
  }

  /* ---------------------------------------------------
  Layout
  --------------------------------------------------- */

  /* header
  --------------------------------------------------------- */

  .l-header {
    background: #fff;
    height: 10mm;
    padding: 0;
    margin-bottom: 5mm;
    border-radius: 5px;
  }

  .l-header__inner {
    margin: 0 auto;
    padding: 0;
    position: relative;
    height: 100%;
    max-width: 1170px;
    position: relative;
  }

  .l-header__inner__logo {
    width: 250px;
    height: 100%;
    display: flex;
    align-items: center;
  }

  .l-header__inner__logo img {
    display: block;
    width: 250px;
  }

  .l-head__logout {
    position: absolute;
    top: 18px;
    right: 305px;
    line-height: 1.2;
    text-align: center;
  }

  .c-head_logout_icon {
    color: #d4145a;
  }

  .l-head__logout a {
    text-decoration: none;
  }

  .l-head__logout span {
    font-size: 10px;
    display: block;
    letter-spacing: 0.2em;
  }

  .l-head__loginname {
    position: absolute;
    top: 12px;
    right: 100px;
  }

  .c-head_user_icon {
    color: #d4145a;
    margin-right: 0.2em;
  }

  .breadcrumb {
    background: #fff;
    width: 1170px;
    height: 40px;
    line-height: 40px;
    padding: 0 20px;
    font-size: 12px;
    text-align: center;
    color: #888;
    border-radius: 30px;
    position: absolute;
    inset: auto 0;
    margin: auto;
    top: 80px;
  }

  /* base
--------------------------------------------------------- */
  .c-basic_area {
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 1em;
    margin-bottom: 1em;
    position: relative;
  }

  .c-pickup_box {
    display: flex;
    gap: 2mm;
    flex-direction: column;
  }

  .c-pickup {
    display: flex;
  }

  .c-pickup_title {
    writing-mode: vertical-rl;
    background: #d4145a;
    color: #fff;
    text-align: center;
    height: 5em;
  }

  .c-pickup_text {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    border-top: 2px solid #ddd;
    border-bottom: 2px solid #ddd;
    line-height: 1.2;
    padding: 0 1em;
  }

  .c-pickup_text span {
    font-size: 30px;
    font-weight: bold;
    margin-right: 0.2em;
  }

  .c-pickup_text__qualification {
    font-size: 14px;
  }

  .c-box {
    display: flex;
    flex-wrap: wrap;
    gap: 0 5mm;
    border-bottom: 1pt solid #ddd;
    margin-bottom: 2mm;
    padding-bottom: 2mm;
  }

  div .c-box:last-child {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .c-box .c-box_label {
    border: 1px solid #d4145a;
    color: #d4145a;
    display: inline-block;
    margin-right: 2mm;
    border-radius: 3px;
    padding: 0.3em 1em;
    font-size: 8pt;
    line-height: 1;
  }

  .grid_box {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
  }

  // .grid_skill {
  //   flex: 1;
  //   display: grid;
  //   /* グリッドコンテナを作る */
  //   grid-template-columns: 1fr 1fr 1fr 1fr;
  //   /* 縦向きのグリッドラインを決める */
  //   grid-template-rows: 1fr 1fr 1fr;
  //   /* 横向きのグリッドラインを決める */
  //   height: 350px;
  //   /* 高さ指定 */
  //   gap: 10px;
  // }

  // .grid_phase {
  //   flex: 1;
  //   display: grid;
  //   /* グリッドコンテナを作る */
  //   grid-template-columns: 1fr 1fr;
  //   /* 縦向きのグリッドラインを決める */
  //   grid-template-rows: 1fr 1fr 1fr;
  //   /* 横向きのグリッドラインを決める */
  //   height: 350px;
  //   /* 高さ指定 */
  //   gap: 10px;
  // }

  // .grid_roll {
  //   flex: 100%;
  //   display: grid;
  //   /* グリッドコンテナを作る */
  //   grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  //   /* 縦向きのグリッドラインを決める */
  //   grid-template-rows: 1fr;
  //   /* 横向きのグリッドラインを決める */
  //   height: 50px;
  //   /* 高さ指定 */

  //   width: 100%;
  // }

  // .grid_skill .grid-child {
  //   padding: 10px;
  //   /* 余白指定 */
  //   border-radius: 5px;
  //   color: #fff;
  //   /* 文字色指定 */
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   line-height: 1.2;
  // }

  // .grid_skill .grid-child:nth-child(1) {
  //   background-color: #d4145a;
  //   /* 背景色 */
  //   grid-column: 1/2;
  //   /* 配置指定 */
  //   grid-row: 1/3;
  //   /* 配置指定 */
  // }

  // .grid_skill .grid-child:nth-child(2) {
  //   background-color: #d43d74;
  //   /* 背景色 */
  //   grid-column: 2/3;
  //   /* 配置指定 */
  //   grid-row: 1/4;
  //   /* 配置指定 */
  // }

  // .grid_skill .grid-child:nth-child(3) {
  //   background-color: #d45382;
  //   /* 背景色 */
  //   grid-column: 3/4;
  //   /* 配置指定 */
  //   grid-row: 1/2;
  //   /* 配置指定 */
  // }

  // .grid_skill .grid-child:nth-child(4) {
  //   background-color: #d4688f;
  //   /* 背景色 */
  //   grid-column: 4/5;
  //   /* 配置指定 */
  //   grid-row: 1/4;
  //   /* 配置指定 */
  // }

  // .grid_skill .grid-child:nth-child(5) {
  //   background-color: #d47d9d;
  //   /* 背景色 */
  //   grid-column: 1/2;
  //   /* 配置指定 */
  //   grid-row: 3/4;
  //   /* 配置指定 */
  // }

  // .grid_skill .grid-child:nth-child(6) {
  //   background-color: #d492aa;
  //   /* 背景色 */
  //   grid-column: 3/4;
  //   /* 配置指定 */
  //   grid-row: 2/4;
  //   /* 配置指定 */
  // }

  // .grid_phase .grid-child {
  //   padding: 10px;
  //   /* 余白指定 */
  //   border-radius: 5px;
  //   /* 角丸指定 */

  //   /* 周りの余白指定 */
  //   color: #d4145a;
  //   /* 文字色指定 */
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   line-height: 1.2;
  //   box-sizing: border-box;
  // }

  // .grid_phase .grid-child:nth-child(1) {
  //   border: 2px solid #d4145a;
  //   /* 背景色 */
  //   grid-column: 1/2;
  //   /* 配置指定 */
  //   grid-row: 1/3;
  //   /* 配置指定 */
  // }

  // .grid_phase .grid-child:nth-child(2) {
  //   border: 2px solid #d4145a;
  //   /* 背景色 */
  //   grid-column: 2/3;
  //   /* 配置指定 */
  //   grid-row: 2/4;
  //   /* 配置指定 */
  // }

  // .grid_phase .grid-child:nth-child(3) {
  //   border: 2px solid #d4145a;
  //   /* 背景色 */
  //   grid-column: 2/2;
  //   /* 配置指定 */
  //   grid-row: 1/2;
  //   /* 配置指定 */
  // }

  // .grid_phase .grid-child:nth-child(4) {
  //   border: 2px solid #d4145a;
  //   /* 背景色 */
  //   grid-column: 1/2;
  //   /* 配置指定 */
  //   grid-row: 3/4;
  //   /* 配置指定 */
  // }

  // .grid_roll .grid-child {
  //   background-image: radial-gradient(#d4145a 1px, #fff 1px);
  //   background-size: 10px 10px;
  //   background-color: rgba(255, 255, 255, 0.9);
  //   background-blend-mode: lighten;
  //   /* 周りの余白指定 */
  //   color: #fff;
  //   /* 文字色指定 */
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   line-height: 1.2;
  //   border-right: none !important;
  // }

  // .grid_roll .grid-child:first-child {
  //   border-top-left-radius: 5px;
  //   border-bottom-left-radius: 5px;
  // }

  // .grid_roll .grid-child:last-child {
  //   border-top-right-radius: 5px;
  //   border-bottom-right-radius: 5px;
  //   border-right: 2px solid #d4145a !important;
  // }

  // .grid_roll .grid-child:nth-child(1) {
  //   border: 2px solid #d4145a;
  //   color: #d4145a;
  //   /* 背景色 */
  //   grid-column: 1/2;
  //   /* 配置指定 */
  // }

  // .grid_roll .grid-child:nth-child(2) {
  //   border: 2px solid #d4145a;
  //   color: #d4145a;
  //   /* 背景色 */
  //   grid-column: 2/4;
  //   /* 配置指定 */
  // }

  // .grid_roll .grid-child:nth-child(3) {
  //   border: 2px solid #d4145a;
  //   color: #d4145a;
  //   /* 背景色 */
  //   grid-column: 4/7;
  //   /* 配置指定 */
  // }

  .c-project_box {
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 2px solid #ccc;
  }

  .c-project_box:last-child {
    margin: 0;
    padding: 0;
    border: none;
  }

  .c-main--area {
    border-radius: 5px;
    background: #fff;
    height: 255mm;
    position: relative;
  }

  .l-container {
    padding: 5mm 0;
    position: relative;
    z-index: 10;
    overflow: hidden;
  }

  .l-inner {
    max-width: 180mm;
    margin: 0 auto;
    background: #fff;
    padding: 5mm;
    border-radius: 5px;
  }

  .c-title--head {
    font-size: 12pt;
    color: #d4145a;
    line-height: 1.2;
    margin-bottom: 3mm;
    letter-spacing: 0.2em;
  }

  .c-text--center {
    text-align: center;
  }

  .c-text--right {
    text-align: right;
  }

  .c-text {
    margin-bottom: 1em;
  }

  footer {
    text-align: center;
    position: absolute;
    width: 100%;
    bottom: 0;
  }

  footer img {
    width: 20mm;
  }

  /*670px*/
  .col {
    float: left;
    margin-right: 2.5641%;
    flex: none;
  }

  .col:last-child {
    margin-right: 0;
  }

  .col_rev {
    float: right !important;
    margin-right: 0 !important;
    margin-left: 2.5641%;
    text-align: right;
  }

  .col_rev:last-child {
    margin-left: 0;
  }

  .col1 {
    width: 5.9829%;
  }

  .col2 {
    width: 14.5299%;
  }

  .col3 {
    width: 23.0769%;
  }

  .col4 {
    width: 31.6239%;
  }

  .col5 {
    width: 40.1709%;
  }

  .col6 {
    width: 48.7179%;
  }

  .col7 {
    width: 57.2649%;
  }

  .col8 {
    width: 65.8119%;
  }

  .col9 {
    width: 74.3589%;
  }

  .col10 {
    width: 82.9059%;
  }

  .col11 {
    width: 91.4529%;
  }

  .col12 {
    width: 100%;
  }
}
