.card-header {
  display: flex;
  gap: 1em;
  align-items: center;
  padding-top: 2em;
  line-height: 1;
}

.card-header p {
  margin: 0;
  font-size: 1rem;
}

.c-title--head {
  font-size: 24px;
  color: #d4145a;
  line-height: 1.2;
  letter-spacing: 0.2em;
  font-weight: bold;
  margin: 0;
}

.radio_adjust {
  padding-top: 0.375rem;
}
